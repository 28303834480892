import * as React from "react"

import ToolScreen from '../../../../components/toolScreen'


const Tool = ({ data }) => (
  <ToolScreen
    name="Adobe XD"
    img_name="adobe_xd_logo.png"
    link="https://www.adobe.com/xd/"
    description="Adobe XD is the most complete design tool tailored for professional UI designers that can run on both Windows and Mac."
    twitterHandleTool="@AdobeXD"
  />
)

export default Tool;


